.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App{
    align-items:center;
    width:100%;
  }
  .App-logo {
    animation: App-logo-spin infinite 20s linear;

  }

  .logoimage{
  width:35%;
  height:auto;
  padding-top:5%;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sticky{
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 1px 5px rgba(25, 50, 47, 0.88)
}

.cookieConsent{

  position: fixed; 
  bottom: 0;
  left: 0;
  width: 100%;
  background-color:#f4f4f4;
  padding: 20px;
  text-align: left;
  height: 25%;
  box-sizing: border-box;
  z-index:1000;}


.cookieConsentTitle{
  font-weight: 800;
  color:#333333;
}

.cookieText{
  color:#333333;
}

.cookieAcceptButton{
background-color:rgb(97, 162, 41);
color:rgb(255,255,255);
padding:10px 20px;
font-size:14px;
border:none;
cursor:pointer;

}

@media only screen and (max-width: 768px) {
.logo-image{
  height:20%;
  width:auto;
}

.cookieConsent{
  height:40%;
  font-size:65%;
  background-color:#f4f4f4;
}

.cookieAcceptButton{
  background-color:rgb(97, 162, 41);
  color:rgb(255,255,255);
  padding:8px 12px;
  font-size:10px;
  border:none;
  cursor:pointer;}


}