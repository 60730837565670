* {
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .layout {
        flex-direction: column;
        /* Stack children vertically */
    }

    .image,
    .contact-us-pic {
        width: 100%;
        /* Take full width */
        flex-shrink: 0;
        /* Prevent the image from shrinking beyond its content */
        /* Any other mobile-specific styles for your image here */
    }

    .form {
        width: 100%;
        /* Take full width */
        /* Any other mobile-specific styles for your form here */
    }
}



@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.fly-in {
    animation: slideInFromLeft 0.5s ease-out forwards;
}

.layout {
    display: flex;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
    padding-left: 10%;
    padding-right: 10%;
    scroll-snap-type: y mandatory;
    /* y-axis snapping */
    /* overflow-y: auto; */
    /* allow vertical scroll */
}

.column-1,
.column-2 {
    scroll-snap-align: start;
    /* snap to the start of each column */
    /* height: 100vh; */
    /* make each column full viewport height */
}

.column-1 {
    padding: 5%;
    display: flex;
    justify-content: start;
    margin: 0 auto;
    flex-direction: column;
    flex-shrink: 0;
    flex: 1;
}

.column-2 {
    display: flex;
    flex-direction: column;
    padding: 5%;
    margin: 0 auto;
    flex: 1;
}

/* It's unfortuante that we have styled divs in one file and raw CSS in another */
@media only screen and (min-width: 320px) {
    .column-1 {
        order: 2;
    }

    .column-2 {
        order: 1;
    }
}

@media only screen and (min-width: 1000px) {
    .column-1 {
        order: 1;
    }

    .column-2 {
        order: 2;
    }
}

.contact-us-pic {
    width: 100%;
    height: auto;
    padding-bottom: 20px;
    padding-left: 2%;
    flex-shrink: 0;
}

.letusknow {
    font-size: 25px;
    padding-bottom: 25px;
    display: flex;
    width: 100%;
    text-align: left;
    color: #383838;
    font-weight: 300;
}


.form {
    justify-content: space-between;
    width: 100%;

}

.input_field {
    border: 1px #909090;
    ;

}

.input_field_space {
    width: 100%;
    display: flex;
    padding: 10px 15px 10px 15px;
}

.input_field_space_large {
    width: 100%;
    display: flex;
    padding: 10px 15px 100px 15px;

}

.privacy_check {
    padding-top: 15px;
    padding-bottom: 10px;
    margin-top: 1%;
    margin-bottom: 1%;
    font-size: 13px;
    font-weight: 400;
    color: #383838;
    text-align: left;
}

.contact_submit_button {
    background-color: #dfdfdf;
    color: #252525;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border: none;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    text-align: center;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.125),
        inset 0 1px 1px rgba(255, 255, 255, 0.5);
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

.contact_submit_button:hover {
    background-color: #c3c3c3;
    color: #000000;
}