


@keyframes popIn {
    0% {
        transform: scale(0.2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.app {
    width: 100%;

}

.About {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* viewport height so only one section is visible at a time */
    overflow-y: scroll;
    /* allows vertical scrolling */
    scroll-snap-type: y mandatory;
    /* snap vertically */
    scroll-behavior: smooth;
    flex-wrap: wrap;
}

.pop-in-animation {
    animation: popIn 0.5s ease-out forwards;
}

.limit-width {
    max-width: 1200px
}

.section {
    scroll-snap-align: start;
    flex-direction: column;
    display: flex;
    align-items: center;
    margin: 3% auto auto auto;
}

.about_pic {
    align-items: center;
    z-index: 0;
    height: auto;
    width: 80%;
}

.mainframe {
    justify-content: center;
    width: 100%;
    display: table;
    text-align: left;
    flex-direction: column;
}

.heading {
    width: 80%;
    font-weight: 300;
    padding-bottom: 20px;
    font-size: 50px;
    margin: auto;
}

.about_text {
    width: 80%;
    font-size: 100%;
    color: #787878;
    clear: left;
    line-height: 25px;
    text-align: left;
    margin: auto;
    text-align: justify;
  text-justify: inter-word;
}

.about_text_list {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20%;
    display: flex;
    align-items: center;
    width: 80%;
    font-size: 100%;
    color: #787878;
    padding-left: 20%;

}




.combined-list{
    display:none;
}

.list-item-with-image-2{
    display:none;
}

.list-item-with-image {
    list-style: none; /* Remove default bullet points */
    padding-left: 20px; /* Add some left padding to make room for the image */
    background-image: url('http://fruba-ip.com/wp-content/uploads/2023/11/white.png');
    background-size: 16px; /* Adjust the size of the image as needed */
    background-repeat: no-repeat;
    background-position: 0 50%; /* Adjust the position of the image (left-centered) */
  }

.founder-container {
    display: flex;
    margin: auto;
    flex-direction: row;
    justify-content: center;
}

.founder-entry {
    margin: 0 2% 0 2%;
}

/* It's unfortuante that we have styled divs in one file and raw CSS in another */
@media only screen and (min-width: 320px) {
    .founder-container {
        flex-direction: column;
        min-width: 320px;
    }

    .founder-entry {
        width: 60%;
        margin: auto;
    }
}

@media only screen and (min-width: 1000px) {
    .founder-container {
        flex-direction: row;
        min-width: 768px;
        width: 67%;
    }

    .founder-entry {
        width: 45%;
        margin: 0 2% 0 2%;
    }
}

.founderphotos {
    justify-content: center;
    display: grid;
    grid-gap: 10px;
    animation: popIn 0.5s forwards;
    /* Apply the pop-in animation */
    width: 100%;
    flex-direction: column;
}

.founders {
    padding-top: 15%;
    padding-bottom: 5%;
}

.founders-section {
    justify-content: space-between;
    display: grid;
    grid-gap: 45px;
    flex-direction: column;
}

.founder-name {
    text-align: left;
    background-color: #BBCBD7;
    line-height: 30.8px;
    font-size: 30px;
    padding-top: 10%;
    padding-bottom: 10%;
    padding-left: 5%;

}

.collapsible-body {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out, padding 0.3s ease-in-out;
    padding: 0 15px;
    /* Adjust as needed */
}

.collapsible-body.expanded {
    max-height: 1000px;
    /* Adjust based on your content's natural height */
    opacity: 1;
    padding: 10px 15px;
    /* Adjust as needed */
    text-align: left;
    line-height: 1.3;
    font-size: 14px;
    color: #777777;
    font-weight: 400;

    border-top: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

/* TODO: centralise these formatting rules across all pages */
.collapsible-body.expanded p,
ul {
    margin-bottom: 14px;
}

/* This is a quickfix for ensuring the markers aren't rendered too much to the left. */
.collapsible-body.expanded ul {
    padding-left: 20px;
}

.icon-toggle {
    cursor: pointer;
    display: inline-block;
    padding: 5px 10px;
    border: 0px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
}

.linkedinlogo {
    padding-top: 5%;
    padding-bottom: 20%;
}

.founder-header {
    display: flex;
    align-items: center;
    background-color: #BBCBD7;
    font-size: 30px;
}

@media (max-width: 768px) {

    /* Styles for mobile devices */
    .section {
        flex-wrap: wrap;
    }

    .mainframe {
        flex-wrap: wrap;
    }

    .heading{
        font-size:200%;
        padding-top:5%;
    }

    .about_text {
        text-align: left;
    }

    .about_text_list {
        flex-direction: column;
        line-height:1.5;
    }
  
    .right-list {
        display: none; /* Hide the original lists */
      }
    .left-list {display: none; /* Hide the original lists */
    }

    .combined-list {
        display:block;
        list-style: none; /* Remove default bullet points */
        
      }
    
    .list-item-with-image{
        display:none;
        padding-left: 20px; /* Add some left padding to make room for the image */
    background-image: url('http://fruba-ip.com/wp-content/uploads/2023/11/white.png');
    background-size: 0px; /* Adjust the size of the image as needed */
    background-repeat: no-repeat;
    background-position: 0 0%; /* Adjust the position of the image (left-centered) */
    }

    .list-item-with-image-2{
        list-style: none; /* Remove default bullet points */
        padding-left: 20px; /* Add some left padding to make room for the image */
        background-image: url('http://fruba-ip.com/wp-content/uploads/2023/11/white.png');
        background-size: 16px; /* Adjust the size of the image as needed */
        background-repeat: no-repeat;
        background-position: 0 50%; /* Adjust the position of the image (left-centered) */
        display:block;
    }

    .founder-entry{

        align-items:center;
        width:90%;
    }


    .founder-name {
        font-size: 22px;
    }

    .founders-section{
        width:auto;

    }

    .founders{
        height:350px;
        width:auto;
        margin-left:8%;
    }

    .founder-header {
        display: flex;
        align-items: center;
        background-color: #BBCBD7;
        font-size: 25px;
    
    }

    .collapsible-body.expanded {
        
    }

}
