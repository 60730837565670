@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');

@keyframes popIn {
    0% {
        transform: scale(1.1);
        /* Starts the image slightly zoomed in */
    }

    100% {
        transform: scale(1);
        /* Ends the image at its normal size */
    }
}

@keyframes popUp {
    0% {
        transform: scale(0.7);
        opacity: 0;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}


.what-we-do-mainframe {
    display: flex;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    grid-template-columns: repeat(2, 1fr);
    height: 100vh;
    align-items: start;
}

.column-1 {
    flex: 1;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    flex-shrink: 0;
    order: 1;

}

.what-we-do-pic {
    width: 100%;
    animation: popIn 0.5s forwards;
    /* Apply the pop-in animation */
    height: auto;
    padding-bottom: 20px;
    padding-left: 5%;
}


.column-2 {
    display: flex;
    flex: 1;
    padding: 10px;
    margin: 0 auto;
    flex-shrink: 0;
    height: auto;
}

.title {
    font-size: 52px;
    padding-right: 2%;
    padding-left: 5%;
    display: flex;
    width: 100%;
    text-align: left;
    color: #383838;
    font-weight: 300;
    display: flex;
}

.what-we-do-text {
    text-align: left;
    font-size: 15px;
    color: #777777;
    font-weight: 400;
    line-height: 2;
    padding-left: 5%;
    padding-right: 10%;
    padding-top: 2%;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: #BECAD6;
    position: relative;
    width: 100%;
    height: 100vh;
    padding: 10%;
    align-items: center;
    grid-gap: 5px;
}

.grid-title {
    color: #666666;
    font-size: 150%;
    font-weight: 300;
    padding-bottom: 2%;
    width: 100%;
    align-self: flex-start;
    grid-column: span 3;
}

.grid-item {
    background-color: #BECAD6;
    font-size: 24px;
    justify-content: center;
    margin: 5px;


}

.icons {
    height: auto;
    width: 40%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}

.grid-item-text {
    color: #6e7071;
    /* Text color */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 55%;
}

/*pop up content style */

.popup-content {
    display: flex;
    align-items: center;
    gap: 40px;
}

.popup-icon-img {
    width: 35%;
    height: auto;
}

.popup-icon-grid {
    order: 1;
    width: 100%;
    height: auto;
    justify-content: center;
    padding-bottom: 5%;
    flex: 0.85;
}

.expanded-text {
    color: #686868;
    font-size: 100%;
    text-align: left;
    font-family: "worksans regular";
    flex: 1;
    order: 2;
    list-style: none;
    padding-left: 0;
    /* Remove default padding */
}

.expanded-text li {
    background: url('http://fruba-ip.com/wp-content/uploads/2023/11/white.png') no-repeat left center;
    background-size: 20px;
    /* Adjust the size of the bullet image as needed */
    padding-left: 35px;
    /* Adjust as needed */
    margin-bottom: 10px;
    text-align: left;
}

close-button {
    color: #686868;
}


.carousel {
    position: relative;
    max-width: 600px;
    /* Set a max-width for the carousel */
    margin: auto;
    padding-top: 3%;
    padding-bottom: 3%;
    display: none;
}

.carousel-wrapper {
    position: relative;
}

.carousel img {
    width: 100%;
    height: auto;
    display: block;
    /* Ensures there's no extra space under the image */
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;
    opacity: 30%;

}

.left-button img {
    height: 35px;
    width: auto;
}


.right-button img {
    height: 35px;
    width: auto;
}

.left-button {
    left: 0;
}

.right-button {
    right: 0;
}


@media only screen and (max-width: 768px) {

    .title {
        font-size: 40px;
    }


    .grid-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        height: 100%;
        width: auto;
    }

    .grid-title {
        font-size: 18px;
        font-weight: 300;
    }

    .grid-item {
        flex: 0 0 50%;
        /* Each item takes up 50% of the container width */
        box-sizing: border-box;
        max-width: calc(100% / 3);
    }

    .icons {
        height: auto;
        width: 80%;
    }

    .what-we-do-mainframe {
        flex-direction: column;

    }

    .what-we-do-pic {
        width: 100%;
        height: auto;
    }


    .popup-content {
        flex-direction: column;
        /* Stack children vertically in reverse order */
        align-items: center;
        gap: 5%;

    }

    .popup-icon-grid {
        order: 1;
        /* Make sure this comes first */
        width: 100%;
        /* Increase the size of the icon */
        padding-right: 0;
        align-items: center;
        justify-content: center;
        flex: 0.8;
        padding-bottom: 0%;

    }

    .popup-icon-img {
        max-width: none;
        width: 40%;
        height: auto;
        /* Maintain aspect ratio */

    }

    .popup-icon-title {
        font-size: 75%;
    }


    .expanded-text {
        order: 2;
        /* This will come after the popup-icon-grid */
        font-size: 80%;
        flex: 1.2;
        text-align: left;
        left: 2%;
        right: 2%;

    }

    .expanded-text li {
        background-size: 18px;
        /* Adjust the size of the bullet image as needed */
        padding-left: 30px;
        /* Adjust as needed */
        margin-bottom: 10px;
        margin-top: 0px;
        text-align: left;
        background-size: 18px;
    }


    .carousel {
        padding-top: 10%;
        padding-bottom: 40%;
    }


}